import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "store";
import "./index.scss";
import App from "./App";
import "./style/main.sass";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Analytics } from "@vercel/analytics/react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      <Provider store={store}>
        <App />
      </Provider>
      <Analytics
        beforeSend={(event) => {
          if (event.url.includes("/private")) {
            return null;
          }
          return event;
        }}
      />
    </>
  </React.StrictMode>
);
