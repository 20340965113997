import { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { HOME_ROUTE, PORTFOLIO_ROUTE, POLICY_ROUTE, ADMIN_ROUTE, LOGIN_PAGE_ROUTE, ADMIN_WORKS_ROUTE, NEWS_ROUTE } from './utils/const'

import { useAuth } from './hooks/useAuth'

const AppPages = lazy(() => import('./Pages/AppPages'))
const HomePage = lazy(() => import('./Pages/Home/Home'))
const PortfolioPage = lazy(() => import('./Pages/Portfolio/Portfolio'))
const Policy = lazy(() => import('./Pages/Policy/Policy'))
const News = lazy(() => import('./Pages/News/News'))
const Admin = lazy(() => import('./Pages/Admin/Admin'))
const EditPage = lazy(() => import('./Pages/EditPage/EditPage'))
const Works = lazy(() => import('./Pages/Works/Works'))
const LoginPage = lazy(() => import('./Pages/LoginPage/LoginPage'))

const useRoutes = () => {
	const { isAuth } = useAuth()

	const PrivateRoutes = ({ children }) => {
		if (isAuth) {
			return children || <Navigate to="/admin" />
		}
		return <Navigate to="/auth" />
	}

	return (
		<Routes>
			<Route path={HOME_ROUTE} element={<AppPages />}>
				<Route index element={<HomePage />} />
				<Route path={PORTFOLIO_ROUTE} element={<PortfolioPage />} />
				<Route path={POLICY_ROUTE} element={<Policy />} />
				<Route path={NEWS_ROUTE} element={<News />} />
				<Route
					path={ADMIN_ROUTE}
					element={
						<PrivateRoutes>
							<Admin />
						</PrivateRoutes>
					}
				/>
				<Route
					path={ADMIN_WORKS_ROUTE}
					element={
						<PrivateRoutes>
							<Works />
						</PrivateRoutes>
					}
				/>
				<Route
					path={`${ADMIN_WORKS_ROUTE}/:id`}
					element={
						<PrivateRoutes>
							<EditPage />
						</PrivateRoutes>
					}
				/>
				<Route path={LOGIN_PAGE_ROUTE} element={<LoginPage />} />

				<Route path="*" element={<HomePage />} />
			</Route>
		</Routes>
	)
}

export default useRoutes
