import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import useRoutes from './routes'
import './App.scss'
import Loader from './Layouts/Loader/Loader'
import { getAuth } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { setUser } from './store/slices/userSlice'
import 'react-toastify/dist/ReactToastify.css'

function App() {
	const dispatch = useDispatch()
	const auth = getAuth()

	auth.onAuthStateChanged(user => {
		if (user) {
			dispatch(
				setUser({
					email: user?.email,					
					id: user?.id,
					token: user?.token					
				})
			)
		} else {
			console.log('The user logged out...')
		}
	})

	const routes = useRoutes()

	return (
		<BrowserRouter>
			<Suspense fallback={<Loader />}>{routes}</Suspense>			
		</BrowserRouter>
	)
}

export default App
